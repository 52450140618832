import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../../components/Layout';
import Banner from '../../components/Banner';
import DonorfyForm from '../../components/DonorfyForm';

const PaySinglePage = ({ location: { state } }) => {
  const {
    datoCmsPay: {
      seoMetaTags,
      title,
      bannerImage,
      bannerText,
      privacyNotice,
      giftAidForm,
    },
  } = useStaticQuery(graphql`
    query PaySinglePageQuery {
      datoCmsPay {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerImage {
          ...SubPageBannerImageFragment
        }
        bannerText
        privacyNotice {
          ...LinkFragment
        }
        giftAidForm {
          url
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} image={bannerImage} text={bannerText} />
        <DonorfyForm
          amount={state && state.amount && parseInt(state.amount).toFixed(2)}
          regular={state && state.regular}
          giftAidForm={giftAidForm}
          privacyNotice={privacyNotice}
          isSingle={true}
        />
      </main>
    </Layout>
  );
};

export default PaySinglePage;
